<template>
  <section id="talleres">
    <MenuVistas-comp />
    <v-container class="cont_superior px-5 px-lg-12">
      <h1 class="titulo mb-5">Pregunta por los talleres!</h1>

      <v-row class="justify-end">
        <v-col cols="12" class="">
          <p class="textos text-justify">
            ¡Bienvenidx a nuestro espacio de exploración y aprendizaje sobre la
            sexualidad! Nuestros talleres te brindan la oportunidad de descubrir
            y comprender tu propia sexualidad de una manera abierta, inclusiva y
            respetuosa.
          </p>
          <v-btn size="large" class="boton mb-3">
            <a
              class="enlace"
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=524424902457&text=Hola%20necesito%20informes%20sobre%20los%20talleres%20!"
              >Solicitar informes</a
            >
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="cont_inferior">
      <v-row class="px-sm-12" justify="center">
        <v-col cols="11" sm="9" md="10" lg="8" xl="5" class="cont_img">
          <v-img
            class="mx-auto"
            src="../assets/talleres/taller_general.jpeg"
            alt=""
          />
        </v-col>
        <v-col cols="11" sm="9" md="10" lg="8" xl="5" class="cont_img">
          <v-img
            class="mx-auto"
            src="../assets/talleres/taller_masaje_01.jpg"
            alt=""
          />
        </v-col>
        <v-col cols="11" sm="9" md="10" lg="8" xl="5" class="cont_img">
          <v-img
            class="mx-auto"
            src="../assets/talleres/taller_masaje_02.jpg"
          />
        </v-col>
        <v-col cols="11" sm="9" md="10" lg="8" xl="5" class="cont_img">
          <v-img
            class="mx-auto"
            src="../assets/talleres/bondage.jpg"
            alt=""
          />
        </v-col>
        <v-col cols="11" sm="9" md="10" lg="8" xl="5" class="cont_img">
          <v-img
            class="mx-auto"
            src="../assets/talleres/bondage_02.jpg"
          />
        </v-col>
      </v-row>
    </v-container>
    <Footer-comp />
  </section>
</template>

<script>
import MenuVistasComp from "@/components/MenuVistasComp.vue";
import FooterComp from "@/components/FooterComp.vue";

export default {
  name: "HomeView",
  components: {
    MenuVistasComp,
    FooterComp,
  },
};
</script>

<style lang="scss">
#talleres {
  width: 100%;
  background: var(--negro);

  .cont_superior {
    background: var(--gris);
    padding-top: 80px;
    border-radius: 0 0 4px 4px;
  }

  .cont_inferior {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .cont_img {
      width: 100%;
    }
  }
}
</style>
